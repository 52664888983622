import React, { useEffect, useState } from "react";
import axios from "axios";
import TruckDetails from "../../atoms/TruckDetails/TruckDetails";
import jwtDecode from "jwt-decode";
import './Main.scss'

const Main = () => {
  const token = localStorage.getItem("FBIdToken");
  let authenticated;
  if (token) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (decodedToken?.exp * 1000 < Date.now()) {
      authenticated = false;
    } else {
      authenticated = true;
    }
  }

  const url = process.env.REACT_APP_BASE_URL_API || "https://us-central1-infratruckapp-c1e68.cloudfunctions.net/api";
  const [trucks, setTrucks] = useState([]);
  useEffect(() => {
    axios.get(`${url}/trucks`).then((data) => {
      console.log(data);
      setTrucks(data.data);
    });
  }, []);
  const [files, setFiles] = useState({});
  const [downloadedImage, setDownloadedImage] = useState(null);

  const handleFileChange = (e) => {
    console.log("target:", e.target.files[0]);
    setFiles(e.target.files[0]);
  };
  console.log("usestate: ", files);

  const handleUploadFile = () => {
    console.log("came here");
    const formData = new FormData();

    formData.append("image", files, files.name);

    console.log("starting axios");

    axios.post(`${url}/truck/image`, formData).then((data) => {
      console.log(data);
      setDownloadedImage(data.data);
    });
  };

  return (
    <>
      <div>
        {trucks.length > 0 ? (<>
          <h1>Trucks Available</h1>
          <div className="container-main">
            {trucks.map((truck) => (
              <div className="card-main">
                <TruckDetails key={truck?.truckId} truck={truck} />
              </div>
            ))}
          </div>
          </>
        ) : (
          <h1>Loading . . . </h1>
        )}
      </div>
    </>
  );
};

export default Main;
