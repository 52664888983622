import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TrailerDetails from "../../atoms/TrailerDetails/TrailerDetails";
import "./Trailers.scss";

const Trailers = () => {
  const url = process.env.REACT_APP_BASE_URL_API || "https://us-central1-infratruckapp-c1e68.cloudfunctions.net/api";
  const [trailers, setTrailers] = useState([]);
  useEffect(() => {
    axios.get(`${url}/trailers`).then((data) => setTrailers(data.data));
  }, []);

  console.log(trailers);
  return (
    <div>
      {trailers.length > 0 ? (
        <>
          <h1>Trailers Available</h1>
          <div className="container-trailer">
            {trailers.map((trailer) => (
              <div className="card-trailer">
                <TrailerDetails key={trailer?.trailerId} trailer={trailer} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <h1>Loading . . . </h1>
      )}
    </div>
  );
};

export default Trailers;
