import React from 'react'

const ParkingLots = () => {
    return (
        <>
            <h1>Parking Lots Coming Soon . . .</h1>
        </>
    )
}

export default ParkingLots