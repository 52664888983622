import React from "react";
import "./TrailerDetails.scss";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from "react-router";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";


const TrailerDetails = (props) => {
  const { trailerId, year, model, make, price, imageURL, createdAt, contact } = props.trailer;
  dayjs.extend(relativeTime)
  const navigation = useNavigate();
  const handleClick = () => {
    localStorage.setItem("currentTrailer", JSON.stringify({trailerId, year, model, make, contact, price, imageURL, createdAt, timePosted: dayjs(createdAt).fromNow()}));
    navigation({
      pathname: '/trailer',
      search: `?id=${trailerId}`
    });
  };
  return (
    <>
      <Card sx={{ maxWidth: 345 }} style={{width: 340, height: 400, margin: 30}} onClick={handleClick}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={imageURL}
            alt="trailer"
            className="image_trailer"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {year} {make} {model}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <p>${price}</p>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <p>{dayjs(createdAt).fromNow()}</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default TrailerDetails;
