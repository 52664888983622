import React from 'react'

const PostTruck = () => {
    return (
        <>
            <h1>Post Truck page</h1>
        </>
    )
}

export default PostTruck