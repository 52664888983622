import React from "react";
import "./CompanyDetails.scss";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";


const CompanyDetails = (props) => {
  const { title, name, location, imageURL, createdAt } = props.company;
  dayjs.extend(relativeTime)
  return (
    <>
      {/* <div className='truck_card'>
                <div className='truck_img'>
                    <img src={imageURL} alt="truck"/>
                </div>
                <div className='truck_details'>
                    <h3>{year} {make} {model}</h3>
                    <h2>{"$"}{price}</h2>
                </div>
                
            </div> */}
      <Card sx={{ maxWidth: 345 }} style={{maxWidth: 340, margin: 30}} onClick={()=>console.log('clicked card')}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={imageURL}
            alt="company"
            className="image_company"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Job Title: {title}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              Company name: {name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <p>Location: {location}</p>
              <p>{dayjs(createdAt).fromNow()}</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CompanyDetails;
