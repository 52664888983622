import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import "./App.scss";
import Login from "./molecules/Login/Login";
import Main from "./molecules/Main/Main";
import PostTruck from "./molecules/PostTruck/PostTruck";
import SignUp from "./molecules/SignUp/SignUp";
import NavBar from "./molecules/NavBar/NavBar";
import jwtDecode from 'jwt-decode'
import AuthRoute from './util/AuthRoute'
import Trailers from "./molecules/Trailers/Trailers";
import RepairShops from "./molecules/RepairShops/RepairShops";
import HiringCompanies from "./molecules/HiringCompanies/HiringCompanies";
import ParkingLots from "./molecules/ParkingLots/ParkingLots";
import TruckPage from "./atoms/TruckPage/TruckPage";
import TrailerPage from "./atoms/TrailerPage/TrailerPage";

const token = localStorage.getItem('FBIdToken')
let authenticated
if(token) {
  const decodedToken = jwtDecode(token)
  console.log(decodedToken)
  if(decodedToken?.exp * 1000 < Date.now()){
    authenticated = false
  } else {
    authenticated = true
  }
}

const App = () => {
  return (
    <>
      <Router>
        <NavBar />
        <div className="container">
          <Routes>
            <Route path="/" Component={Main} />
            <Route path="/login" Component={authenticated ? AuthRoute: Login} />
            <Route path="/signup" Component={authenticated ? AuthRoute: SignUp}/>
            <Route path="/posttruck" Component={PostTruck} />
            <Route path="/trailers" Component={Trailers} />
            <Route path="/repairs" Component={RepairShops}/>
            <Route path="/companies" Component={HiringCompanies} />
            <Route path="/parking" Component={ParkingLots} />
            <Route path={`/truck`} Component={TruckPage} />
            <Route path={`/trailer`} Component={TrailerPage} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
