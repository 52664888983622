import React from "react";
import "./DrawerMenu.scss";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { toggleDrawer } from "../../util/drawerMenuUtils";

const DrawerMenu = () => {
  const token = localStorage.getItem("FBIdToken");
  let authenticated;
  if (token) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (decodedToken?.exp * 1000 < Date.now()) {
      authenticated = false;
    } else {
      authenticated = true;
    }
  }

  return (
    <>
      <div className="drawer drawer-close" id="drawer">
        <div className="nav-part3">
          <Button
            className="nav-button1"
            color="inherit"
            component={Link}
            to="/"
            onClick={() => toggleDrawer()}
          >
            TRUCKS
          </Button>
          <Button
            className="nav-button1"
            color="inherit"
            component={Link}
            to="/trailers"
            onClick={() => toggleDrawer()}
          >
            TRAILERS
          </Button>
          <Button
            className="nav-button1"
            color="inherit"
            component={Link}
            to="/blogs"
            onClick={() => toggleDrawer()}
          >
            BLOGS
          </Button>
          <Button
            className="nav-button1"
            color="inherit"
            component={Link}
            to="/about"
            onClick={() => toggleDrawer()}
          >
            ABOUT
          </Button>
          {!authenticated && (
            <>
              <Button
                className="nav-button1"
                color="inherit"
                component={Link}
                to="/login"
                onClick={() => toggleDrawer()}
              >
                LOGIN
              </Button>
            </>
          )}
          {!authenticated && (
            <>
              <Button
                className="nav-button1"
                color="inherit"
                component={Link}
                to="/signup"
                onClick={() => toggleDrawer()}
              >
                SIGN UP
              </Button>
            </>
          )}
          {authenticated && (
            <>
              <Button
                className="nav-button-marked"
                color="inherit"
                component={Link}
                to="/post"
                onClick={() => toggleDrawer()}
              >
                ADD YOUR POST
              </Button>
            </>
          )}
          {authenticated && (
            <>
              <Button
                className="nav-button1"
                color="inherit"
                onClick={() => {
                  localStorage.removeItem("FBIdToken");
                  window.location.href = "/";
                }}
              >
                LOG OUT
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DrawerMenu;
