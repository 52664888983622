import React from "react";
import "./TruckDetails.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

const TruckDetails = (props) => {
  const {
    year,
    model,
    make,
    contact,
    price,
    imageURL,
    mileage,
    createdAt,
    truckId,
  } = props.truck;
  dayjs.extend(relativeTime);
  const navigation = useNavigate();
  const handleClick = () => {
    localStorage.setItem("currentTruck", JSON.stringify({truckId, year, model, make, contact, price, imageURL, mileage, createdAt, timePosted: dayjs(createdAt).fromNow()}));
    navigation({
      pathname: '/truck',
      search: `?id=${truckId}`
    });
  };
  return (
    <>
      <Card
        sx={{ maxWidth: 345 }}
        style={{ width: 340, height: 400, margin: 30 }}
        onClick={handleClick}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={imageURL}
            alt="truck"
            className="image_truck"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {year} {make} {model}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <p>${price}</p>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <p>{dayjs(createdAt).fromNow()}</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default TruckDetails;
