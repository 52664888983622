import React from "react";
import axios from "axios";
import "./TruckPage.scss";
const TruckPage = () => {
  const url = process.env.REACT_APP_BASE_URL_API
  const truckDetailsorId =
    JSON.parse(localStorage.getItem("currentTruck")) ||
    localStorage.getItem("truckId");

  typeof truckDetailsorId !== "object" &&
    axios.post(`${url}/truckdetail`, { truckId: truckDetailsorId }).then((res) => {
      console.log(res);
    });

  return (
    <>
      <div className="main">
        <div className="truck-image1">
          <img src={truckDetailsorId?.imageURL} alt="truck" />
        </div>
        <div className="truck-data">
          <div>
            <p className="title1">Posted {truckDetailsorId?.timePosted}</p>
          </div>
          <div className="model">
            <h1>
              {truckDetailsorId?.year} {truckDetailsorId?.make}{" "}
              {truckDetailsorId?.model}
            </h1>
          </div>
          <div className="truck-facts">
            <div className="truck-facts-label">
              <p>Mileage:</p>
              <p>Contact:</p>
              <p>Price:</p>
            </div>
            <div className="truck-facts-value">
              <p>{truckDetailsorId?.mileage}</p>
              <p>{truckDetailsorId?.contact}</p>
              <p>{truckDetailsorId?.price}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TruckPage;
