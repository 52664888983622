import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Link, useNavigate} from 'react-router-dom'
import './Login.scss'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { red } from '@material-ui/core/colors'
import jwtDecode from 'jwt-decode'

const Login = (props) => {
    let navigation = useNavigate()
    const token = localStorage.getItem("FBIdToken");
  let authenticated;
  if (token) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (decodedToken?.exp * 1000 < Date.now()) {
      authenticated = false;
    } else {
      authenticated = true;
    }
  }
    if(authenticated === true) window.location.href = '/'
    const url = process.env.REACT_APP_BASE_URL_API || 'https://us-central1-infratruckapp-c1e68.cloudfunctions.net/api'
    console.log(props)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const handleSubmit = (e) => {
        console.log('submitted')
        e.preventDefault()
        setLoading(true)
        const userData = {
            email: email,
            password: password
        }
        axios.post(`${url}/login`, userData).then(res => {
            console.log(res.data)
            localStorage.setItem('FBIdToken', `Bearer ${res.data.idToken}`)
            setLoading(false)
            // navigation('/')
            window.location.href('/')
        })
        .catch(err => {
            setErrors({errors: err.response.data})
            setLoading(false)
        })
    }
    const handleChange = (e) => {
        console.log('changed')
        if(e.target.name === 'email'){
            setEmail(e.target.value)
        } else {
            setPassword(e.target.value)
        }
    }
    return (
        <>
            <h1 className='login_headline'>Login</h1>
            <div className='login_container'>
            <form noValidate onSubmit={handleSubmit}>
                <TextField id="email" name='email' type="email" label="Email"
                value={email} onChange={handleChange} fullWidth className='form_field'
                helperText={errors?.errors?.email} error={errors?.errors?.email ? true : false}/>
                <TextField id="password" name='password' type="password" label="Password"
                value={password} onChange={handleChange} fullWidth  className='form_field'
                helperText={errors?.errors?.password} error={errors?.errors?.password ? true : false}/>
                {errors?.errors?.error && (
                    <Typography variant='body2' className='general_error'>
                        Wrong credentials! Let's try it again!
                    </Typography>
                )}
                <Button type="submit" variant='contained' color='primary' className='form_button'
                disabled={loading}
                >Login
                </Button>
                <br/>
                <small>Don't have an account? Sign up <Link to="/signup">here</Link></small>
            </form>
            </div>
        </>
    )
}

export default Login