import axios from 'axios'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import CompanyDetails from '../../atoms/CompanyDetails/CompanyDetails'
import './HiringCompanies.scss'

const HiringCompanies = () => {
    const url = process.env.REACT_APP_BASE_URL_API || "https://us-central1-infratruckapp-c1e68.cloudfunctions.net/api";
    const [companies, setCompanies] = useState([])
    useEffect(()=>{
        axios.get(`${url}/companies`).then((data)=> setCompanies(data.data))
    }, [])

    console.log(companies)
    return (
        
        <div>
            {companies.length > 0 ? (<>
                <h1>Companies Available</h1>
          <div className='container-company'>
            {companies.map((company) => (
                <div className='card-company'>
              <CompanyDetails key={company?.companyId} company={company} />
              </div>
            ))}
          </div>
          </>
        ) : (
          <h1>Loading . . . </h1>
        )}
        
        </div>
    )
}

export default HiringCompanies