import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import DrawerMenu from "../../atoms/DrawerMenu/DrawerMenu";
import DehazeIcon from '@mui/icons-material/Dehaze';

//MUI stuff
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import "./NavBar.scss";
import { toggleDrawer } from "../../util/drawerMenuUtils";

const NavBar = () => {
  const token = localStorage.getItem("FBIdToken");
  const [mobileView, setMobileView] = useState(window.innerWidth < 784);
  let authenticated;
  if (token) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (decodedToken?.exp * 1000 < Date.now()) {
      authenticated = false;
    } else {
      authenticated = true;
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 784 && !mobileView) {
      setMobileView(!mobileView);
    } else if (window.innerWidth >= 784 && mobileView) {
      setMobileView(!mobileView);
    }
  };
  window.addEventListener("resize", handleResize);

  return (
    <>
      <AppBar>
        <Toolbar className="nav-container">
          {!mobileView && (
            <Button
              color="inherit"
              className="nav-part1"
              component={Link}
              to="/"
            >
              InfraTruck
            </Button>
          )}
          {!mobileView && (
            <div className="nav-part2">
              <Button
                className="nav-button"
                color="inherit"
                component={Link}
                to="/"
              >
                TRUCKS
              </Button>
              <Button
                className="nav-button"
                color="inherit"
                component={Link}
                to="/trailers"
              >
                TRAILERS
              </Button>
              |
              <Button
                className="nav-button"
                color="inherit"
                component={Link}
                to="/blogs"
              >
                BLOGS
              </Button>
              |
              <Button
                className="nav-button"
                color="inherit"
                component={Link}
                to="/about"
              >
                ABOUT
              </Button>
              {!authenticated && (
                <>
                  |
                  <Button
                    className="nav-button"
                    color="inherit"
                    component={Link}
                    to="/login"
                  >
                    LOGIN
                  </Button>
                </>
              )}
              {!authenticated && (
                <>
                  |
                  <Button
                    className="nav-button"
                    color="inherit"
                    component={Link}
                    to="/signup"
                  >
                    SIGN UP
                  </Button>
                </>
              )}
              {authenticated && (
                <>
                  |
                  <Button
                    className="nav-button-marked"
                    color="inherit"
                    component={Link}
                    to="/post"
                  >
                    ADD YOUR POST
                  </Button>
                </>
              )}
              {authenticated && (
                <>
                  |
                  <Button
                    className="nav-button"
                    color="inherit"
                    onClick={() => {
                      localStorage.removeItem("FBIdToken");
                      window.location.href = "/";
                    }}
                  >
                    LOG OUT
                  </Button>
                </>
              )}
            </div>
          )}
          {mobileView && (<Button
                    className="nav-button"
                    color="inherit"
                    onClick={() => toggleDrawer()}
                  >
                    <DehazeIcon/>
                  </Button>)}
        </Toolbar>
      </AppBar>
      <DrawerMenu/>
    </>
  );
};

export default NavBar;
