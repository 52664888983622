import React from 'react'

const RepairShops = () => {
    return (
        <>
            <h1>Repair Shops Coming Soon . . .</h1>
        </>
    )
}

export default RepairShops