import React from "react";
import axios from "axios";
import "./TrailerPage.scss";
const TrailerPage = () => {
  const url = process.env.REACT_APP_BASE_URL_API
  const trailerDetailsorId =
    JSON.parse(localStorage.getItem("currentTrailer")) ||
    localStorage.getItem("trailerId");

  typeof trailerDetailsorId !== "object" &&
    axios.post(`${url}/trailerdetail`, { trailerId: trailerDetailsorId }).then((res) => {
      console.log(res);
    });

  return (
    <>
      <div className="main">
        <div className="truck-image1">
          <img src={trailerDetailsorId?.imageURL} alt="truck" />
        </div>
        <div className="truck-data">
          <div>
            <p className="title1">Posted {trailerDetailsorId?.timePosted}</p>
          </div>
          <div className="model">
            <h1>
              {trailerDetailsorId?.year} {trailerDetailsorId?.make}{" "}
              {trailerDetailsorId?.model}
            </h1>
          </div>
          <div className="truck-facts">
            <div className="truck-facts-label">
              <p>Contact:</p>
              <p>Price:</p>
            </div>
            <div className="truck-facts-value">
              <p>{trailerDetailsorId?.contact}</p>
              <p>{trailerDetailsorId?.price}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrailerPage;
