import React, {useState} from 'react'
import jwtDecode from 'jwt-decode'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Link, useNavigate} from 'react-router-dom'
import './SignUp.scss'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { red } from '@material-ui/core/colors'

const SignUp = (props) => {
    const url = process.env.REACT_APP_BASE_URL_API || 'https://us-central1-infratruckapp-c1e68.cloudfunctions.net/api'
    console.log(props)
    let navigation = useNavigate()
    const token = localStorage.getItem("FBIdToken");
  let authenticated;
  if (token) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (decodedToken?.exp * 1000 < Date.now()) {
      authenticated = false;
    } else {
      authenticated = true;
    }
  }
  console.log(authenticated)
  if(authenticated === true) window.location.href = '/'
  console.log('did it come here')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [confirmPassword, setConfirmPassword] = useState('')
    const [handle, setHandle] = useState('')
    const handleSubmit = (e) => {
        console.log('submitted')
        e.preventDefault()
        setLoading(true)
        const newUserData = {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            handle: handle
        }
        axios.post(`${url}/signup`, newUserData).then(res => {
            console.log(res.data)
            localStorage.setItem('FBIdToken', `Bearer ${res.data.token}`)
            setLoading(false)
            navigation('/')
        })
        .catch(err => {
            setErrors({errors: err.response.data})
            setLoading(false)
        })
    }
    const handleChange = (e) => {
        console.log('changed')
        if(e.target.name === 'email'){
            setEmail(e.target.value)
        } else if (e.target.name === 'password') {
            setPassword(e.target.value)
        } else if (e.target.name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        } else if (e.target.name === 'handle') {
            setHandle(e.target.value)
        }
    }
    return (
        <>
            <h1 className='signup_headline'>Sign Up</h1>
            <div className='signup_container'>
            <form noValidate onSubmit={handleSubmit}>
                <TextField id="email" name='email' type="email" label="Email"
                value={email} onChange={handleChange} fullWidth className='form_field'
                helperText={errors?.errors?.email} error={errors?.errors?.email ? true : false}/>
                <TextField id="password" name='password' type="password" label="Password"
                value={password} onChange={handleChange} fullWidth  className='form_field'
                helperText={errors?.errors?.password} error={errors?.errors?.password ? true : false}/>
                <TextField id="confirmPassword" name='confirmPassword' type="password" label="Confirm password"
                value={confirmPassword} onChange={handleChange} fullWidth className='form_field'
                helperText={errors?.errors?.confirmPassword} error={errors?.errors?.confirmPassword ? true : false}/>
                <TextField id="handle" name='handle' type="text" label="Username"
                value={handle} onChange={handleChange} fullWidth className='form_field'
                helperText={errors?.errors?.handle} error={errors?.errors?.handle ? true : false}/>
                {errors?.errors?.error && (
                    <Typography variant='body2' className='general_error'>
                        Wrong credentials! Let's try it again!
                    </Typography>
                )}
                <Button type="submit" variant='contained' color='primary' className='form_button'
                disabled={loading}
                >Sign Up
                </Button>
                <br/>
                <small>Already have an account? login <Link to="/login">here</Link></small>
            </form>
            </div>
        </>
    )
}



export default SignUp